<template>
  <div class=" ">
    <v-card>
      <div class="d-flex flex-no-wrap align-center ">

        <div class="text-center">

          <user-avatar v-if="user.id == idprofile"
                       :size="114" :user="$store.state.user"
                       @changeAvatar="()=> changeavatar = true"></user-avatar>
          <user-avatar v-else
                       :size="114" :user="profile"></user-avatar>

          <h3
            v-if="user.id == idprofile">@{{ user.username }}</h3>
          <h3 v-else>@{{ profile.username }}</h3>
          <template v-if="user_id != idprofile">
            <v-btn @click="startMessaging">Contacter</v-btn>
          </template>
        </div>

        <div class="flex justify-center ">
          <!--            <v-btn class="pa-0 pt-3" v-if="user_id == idprofile" text to="/settings"><v-icon>{{icons.mdiSquareEditOutline}}</v-icon> Parametres</v-btn>-->


          <v-row no-gutters class="text-center  ">
            <v-col>
              <v-list dense class="text-center pr-0 pl-0">
                <v-list-item class="pr-0 pl-0">
                  <v-list-item-content>
                    <v-list-item-title class="text-h5"> {{ profile.nbpost }}</v-list-item-title>
                    <v-list-item-subtitle> Publication(s)</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list dense class="text-center pr-0 pl-0">
                <v-list-item class="pr-0 pl-0" :to="profile.id+'/followers'">
                  <v-list-item-content>
                    <v-list-item-title class="text-h5"> {{ profile.nbfollowers }}
                    </v-list-item-title>
                    <v-list-item-subtitle> Followers</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list dense class="text-center pr-0 pl-0">
                <v-list-item class="pr-0 pl-0" :to="profile.id+'/followers'">
                  <v-list-item-content>
                    <v-list-item-title class="text-h5"> {{ profile.nbfollowing }}
                    </v-list-item-title>
                    <v-list-item-subtitle> Suivi(e)s</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

        </div>
      </div>
      <v-card-actions v-if="user_id == idprofile">
        <v-btn to="/pages/account-settings">Modifier le Profil</v-btn>
        <v-btn color="info" class="ml-3" to="/creator/dashboard">Espace Createur</v-btn>
        <v-btn class="ml-3" to="/settings">Parametre et configuration</v-btn>
        <v-spacer></v-spacer>
        <follow-toggle-component v-if="user.id != profile.id" :status="profile.following"
                                 :current_user_id="parseInt(user_id)"
                                 :user_id="parseInt(idprofile)" @following="followcallback"
        ></follow-toggle-component>
      </v-card-actions>
    </v-card>

    <v-tabs class=" font-secondary"
            v-model="tab" center-active
            align-tabs="center"
            grow
    >
      <v-tab
        key="papers"
      >
        publications
      </v-tab>
      <v-tab
        key="series"
      >
        Mes One-Shot
      </v-tab>
      <v-tab
        key="challeng"
      >
        Concours
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="papers"
        style="min-height: 500px"
      >
        <profile-post-component v-if="profile.id"
                                :postoption="{ 'status._key:eq': 'published', }"
                                :user="user"
                                :idprofile="idprofile"
                                :user_id="user_id"
        ></profile-post-component>

      </v-tab-item>
      <v-tab-item
        :key="'series'"
        style="min-height: 500px"
      >
        <profile-creator-component v-if="profile.id"
                                   :user="user"
                                   :idprofile="idprofile"
                                   :user_id="user_id"
        ></profile-creator-component>

      </v-tab-item>
      <v-tab-item
        :key="'challeng'"
        style="min-height: 500px"
      >
        <profile-contest-component v-if="profile.id"
                                   :user="user"
                                   :idprofile="idprofile"
                                   :user_id="user_id"
        ></profile-contest-component>

      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiLogout,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import LoginComponent from '@/components/LoginComponent'
import {Drequest} from '@/plugins/Drequest'
import ValidateAccountComponent from '@/components/ValidateAccountComponent'
import RegisterComponent from '@/components/RegisterComponent'
import PostMinifiedComponent from '@/components/PostMinifiedComponent'
import PostFormComponent from '@/components/PostFormComponent'
import {useRouter} from '@/utils'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfilePostComponent from '@/components/ProfilePostComponent.vue'
import ProfileContestComponent from "@/components/ProfileContestComponent.vue";
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import ProfileIllustrationComponent from "@/components/ProfileIllustrationComponent.vue";

export default {
  components: {
    ProfileIllustrationComponent,
    UserAvatar,
    ProfileCreatorComponent,
    ProfileContestComponent,
    FollowToggleComponent,
    PostMinifiedComponent,
    ProfilePostComponent
  },
  setup() {
    const dialog = ref({
      newpost: false,
      imageviewer: false,
      authentication: false,
    })
    const {router} = useRouter()
    const route = router.currentRoute
    const idprofile = parseInt(route.params.idprofile)
    const user_id = ref((localStorage.getItem('user_id')))
    const user = ref(Drequest.getUser())
    const subscription = ref({})
    const profile = ref({})
    const tab = ref(0)
    const loaders = ref({
      config: false,
      loading: false,
      loadingseries: false,
      loadingchalleng: false,
      retry: false,
    })
    const configurations = ref([])
    const newaccount = ref(false)
    const changeavatar = ref(false)

    const postdetail = ref({})
    const postll = ref({})
    const postseriesll = ref({})
    const postchallengll = ref({})
    const postoption = ref({'status._key:eq': 'published',})

    const logout = () => {
      Drequest.logout()
      window.location.href = '/settings'
    }

    Drequest.api('detail.user?id=' + idprofile)
      .param({
        user_id: user_id.value
      })
      .get(response => {
        console.log(response)
        if (response.user.id)
          profile.value = response.user
        else
          logout()

      })
      .fail(e => {
        console.log(e)
      })

    if (user_id.value) {
      //todo: relationship
    }

    const logged = (user, message) => {
      console.log(user, message)
      window.location.reload()
    }
    const followcallback = (response) => {
      console.log(response)
      window.location.reload()
      // profile.value = { ...profile.value, ...response }
    }

    const startMessaging = () => {
      Drequest.api('conversation.restaure')
        .param({
          'user_id' : user_id.value,
          'profile_id' : idprofile,
        })
        .get((response) => {
          console.log(response)
          router.push('/messenger/' + response.conversation.id)
      })
    }

    return {
      logout,
      logged,
      startMessaging,
      followcallback,

      profile,
      postdetail,
      tab,
      dialog,
      postll,
      changeavatar,
      newaccount,
      loaders,
      configurations,
      subscription,
      user,
      user_id,
      idprofile,

      icons: {
        mdiClose,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
