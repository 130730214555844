<template>

  <v-card :loading="loaders.loading" flat>


    <v-row dense>
      <v-col
        v-for="(illustration, index) in illustrationll.listEntity"
        :key="index"
        class="d-flex child-flex"
        cols="4"
      >
        <illustration-minified-component
          :key="illustration.id"
          :index="index"
          :illustration-item="illustration"
          :illustrationimage="illustration.src_270"
          :user="user"
          :limit="3"
          @deleted="deleted"
        >
        </illustration-minified-component>
      </v-col>
    </v-row>

    <v-sheet
      v-if="loaders.loading"
      class="mx-auto"
    >
      <v-row dense>
        <v-col
          v-for="n in 9"
          :key="'lnew'+n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-card
            :color="`grey darken-2 `"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              :height="120"
              :width="120"
              type="card"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>


    <v-sheet v-if="!loaders.loading && illustrationll.listEntity.length === 0"
      elevation="0"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        size="112"
      >{{icons.mdiExclamation}}</v-icon>

      <h2 class="text-h5 mb-6">Aucun element trouve</h2>

      <template v-if="user_id == idprofile">
        <p class="mb-4 text-medium-emphasis text-body-2">
          Vous pouvez publier vos propres illustrations.
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none"
            color="success"
            rounded
            variant="flat" :to="'/illustration/form'"
          >
            Publier une Illustration
          </v-btn>
        </div>
      </template>

    </v-sheet>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import PostMinifiedComponent from '@/components/PostMinifiedComponent'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfileItem from "@/components/ProfileItem.vue";
import IllustrationMinifiedComponent from "@/components/IllustrationMinifiedComponent.vue";

export default {
  components: {
    IllustrationMinifiedComponent,
    ProfileItem,
    FollowToggleComponent,
    PostMinifiedComponent,
  },
  props: {
    illustrationoption: Object,
    idprofile: Number|String,
    user_id: Number|String,
    user: Object,
  },
  setup(props) {
    const dialog = ref({
      newillustration: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const illustrationll = ref({})

    const loadPost = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.illustration?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': props.idprofile,
          per_page: 12,
          next,
          user_id: props.user_id,
          ///...props.illustrationoption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            illustrationll.value.listEntity = [...illustrationll.value.listEntity, ...response.listEntity]
            illustrationll.value.next = response.next

          } else {
            loaders.value.loading = false
            illustrationll.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadPost(1)

    const deleted = (index) => {
      illustrationll.value.listEntity.splice(index, 1)
    }

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(illustrationll.value.next >= 2, illustrationll.value)
      // illustrationll.value.next >= 2 &&
      if (illustrationll.value.remain) loadPost(illustrationll.value.next)

    }

    return {
      infiniteScrolling,
      deleted,

      illustrationll,
      loaders,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
